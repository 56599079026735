import styled from "styled-components";
import ThemeToggle from "./ThemeToggle";

const JobBoardNavbar = (props) => {
    return (
        <Container>
            <Content>
                <NavLogo href="https://app.joinblynk.com">
                    <i className="icon-trefoil pe-1" />
                    BLYNK
                </NavLogo>
                <Nav>
                    <NavListWrap>
                        <NavListItem>
                            { props.themeButton &&
                                <ThemeToggle />
                            }
                        </NavListItem>
                    </NavListWrap>
                </Nav>
            </Content>
        </Container>
    );
};
const NavLogo = styled.a`
    color: #000;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 3rem;
    display: flex;
    align-items: center;
    margin-left: 24px;
    font-weight: bold;
    text-decoration: none;

    @media screen and (max-width: 340px) {
        font-size: 2rem;
    }

    &:hover {
        color: #fbaf03;
    }

    &:visited {
        color: #000;

        &:hover {
            color: #fbaf03;
        }
    }
`;

const Container = styled.div`
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    bottom-margin: 24px;
    left: 0;
    padding: 0 24px;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 100;
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    margin: 0 auto;
    min-height: 100%;
    max-width: 1128px;
`;

const Nav = styled.nav`
    margin-left: auto;
    display: block;
    @media (max-width: 768px) {
        position: fixed;
        left: 0;
        bottom: 0;
        background: white;
        width: 100%;
    }
`;

const NavListWrap = styled.ul`
    display: flex;
    flex-wrap: nowrap;
    list-style-type: none;
    .active {
        span:after {
            content: "";
            transform: scaleX(1);
            border-bottom: 2px solid var(--white, #fff);
            bottom: 0;
            left: 0;
            position: absolute;
            transition: transform 0.2s ease-in-out;
            width: 100%;
            border-color: #afb7be;
        }
    }
`;

const NavListItem = styled.li`
    display: flex;
    align-items: center;
    a {
        align-items: center;
        background: transparent;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-weight: 400;
        justify-content: center;
        line-height: 1.5;
        min-height: 52px;
        min-width: 80px;
        position: relative;
        text-decoration: none;
        span {
            // color: #AFB7BE;
            display: flex;
            align-items: center;
        }
        @media (max-width: 768px) {
            min-width: 70px;
        }
    }
`;

export default JobBoardNavbar;
