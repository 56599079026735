import "./App.css";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    // Navigate,
} from "react-router-dom";
import { Component } from "react";
// import jwtDecode from "jwt-decode";
import axios from "axios";
// import { auth } from "./util/firebase";
// import mixpanel from "./util/mixpanel";

// Redux
import { Provider } from "react-redux";
import store from "./redux/store";
// import { SET_AUTHENTICATED } from "./redux/types";
// import { getUserData } from "./redux/actions/userActions";

// Pages
// import Feed from "./pages/Feed";
// import Login from "./pages/Login";
// import SignUp from "./pages/SignUp";
// import User from "./pages/User";
// import Jobs from "./pages/Jobs";
import Privacy from "./pages/Privacy";
// import NotFound from "./pages/NotFound";
import Wildcard from "./pages/Wildcard";
// import Groups from "./pages/Groups";
// import Group from "./pages/Group";
// import Landing from "./pages/Landing";
import ResumeForm from "./pages/ResumeForm";
// import Startups from "./pages/Startups";
// import Startup from "./pages/Startup";
// import Event from "./pages/Event";
// import Events from "./pages/Events";
// import EventLanding from "./pages/EventLanding";
import AppDownload from "./pages/AppDownload";
import Diagram from "./pages/Diagram";
import Redirect from "./pages/Redirect";
import About from "./pages/About";

// Components
// import AuthRoute from "./util/AuthRoute";
import ScrollToTop from "../src/components/layout/ScrollToTop";
import EmployerAbout from "./pages/EmployerAbout";
import JobseekerAbout from "./pages/JobseekerAbout";
import CareerConnections from "./pages/CareerConnections";

// let token = localStorage.FBIdToken;

// Prevent proxy from including url path parameters in axios requests
axios.defaults.baseURL =
    "https://us-central1-blynk-781cc.cloudfunctions.net/api";

// if (token) {
//     const decodedToken = jwtDecode(token);

//     // Check whether the current user's Firebase ID token is expired
//     if (decodedToken.exp * 1000 < Date.now()) {
//         auth.onIdTokenChanged((user) => {
//             if (user) {
//                 user.getIdToken(true).then((idToken) => {
//                     token = idToken.trim();
//                     localStorage.setItem("FBIdToken", `Bearer ${token}`);
//                     axios.defaults.headers.common[
//                         "Authorization"
//                     ] = `Bearer ${token}`;
//                     store.dispatch(getUserData());
//                     store.dispatch({ type: SET_AUTHENTICATED });
//                     mixpanel.track("Login");
//                 });
//             }
//         });
//     } else {
//         axios.defaults.headers.common["Authorization"] = token;
//         store.dispatch(getUserData());
//         store.dispatch({ type: SET_AUTHENTICATED });
//     }
// }

class App extends Component {
    render() {
        return (
            <>
                <Provider store={store}>
                    <Router>
                        <ScrollToTop />
                        <div className="container" style={{ bottom: "0" }}>
                            <Routes>
                                {/* <Route path="/" element={ token? (<Navigate to="/feed" />): (<Landing />) } /> */}
                                <Route path="/" element={ <Redirect url="https://app.joinblynk.com" /> } />
                                {/* <Route path="/landing" element={<Landing />} />
                                <Route path="/feed" element={<Feed />} />
                                <Route path="/jobs" element={<Jobs />} /> */}
                                <Route path="/privacy" element={<Privacy />} />
                                {/* <AuthRoute path="/login" element={<Login />} />
                                <AuthRoute path="/sign-up" element={<SignUp />} />
                                <Route path="/users/:handle/post/:postId" element={<User />} />
                                <Route path="/users/:handle" element={<User />} /> */}
                                <Route path="*" element={<Wildcard url="https://app.joinblynk.com" />} />
                                {/* <Route path="/communities" element={<Groups />} />
                                <Route path="/startups" element={<Startups />} />
                                <Route path="/startups/:id" element={<Startup />} />
                                <Route path="/events" element={<Events />} />
                                <Route path="/events/:id" element={<Event />} />
                                <Route path="/event/landing/:id" element={<EventLanding />} />
                                <Route path="/communities/:groupId" element={<Group />} />                             */}
                                <Route path="/resume" element={<ResumeForm />} />
                                <Route path="/sign-up" element={<Redirect url="https://app.joinblynk.com/registration" />} />
                                <Route path="/join" element={<ResumeForm />} />
                                <Route path="/download" element={<AppDownload />} />
                                <Route path="/labor-shortage-tree-diagram" element={<Diagram />} />
                                <Route path="/harkins2023" element={<Redirect url="https://www.airmeet.com/e/65514420-638c-11ee-b247-496ac66dbdbe" />} />
                                <Route path="/employer" element={<Redirect url="https://app.joinblynk.com/registration/employer" />} />
                                <Route path="ccbc2023" element={<Redirect url="https://forms.gle/SxjwqETGhSvm77kd9"/>}  />
                                <Route path="/about"  element={<About />}/>
                                <Route path="/about-employer" element={<EmployerAbout />} />
                                <Route path="/about-jobseeker" element={<JobseekerAbout />} />
                                <Route path="/career-connections" element={<CareerConnections />} />
                            </Routes>
                        </div>
                    </Router>
                </Provider>
            </>
        );
    }
}

export default App;
