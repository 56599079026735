import React from "react";
import styled from "styled-components";

// Feature Icons Graphics
import firstFeatureIcon from "../images/landing/business_deal.svg";
import secondFeatureIcon from "../images/landing/explorer.svg";
import thirdFeatureIcon from "../images/landing/gettogether.svg";

const LandingFeatures = () => {
    return (
        <FeaturesContainer id="features" className="pt-4 pb-4">
            <FeaturesHeading>How it Works</FeaturesHeading>
            <FeaturesWrapper>
                <FeatureCard>
                    <FeatureIcon src={firstFeatureIcon} />
                    <FeatureSubtitle>Get Hired & Recruit</FeatureSubtitle>
                    <FeatureBody>
                        with recommendations from our directory of career opportunities and diverse talent
                    </FeatureBody>
                </FeatureCard>
                <FeatureCard>
                    <FeatureIcon src={secondFeatureIcon} />
                    <FeatureSubtitle>Explore</FeatureSubtitle>
                    <FeatureBody>
                        new companies, roles and career paths tailored to your values, skills and preferences
                    </FeatureBody>
                </FeatureCard>
                <FeatureCard>
                    <FeatureIcon src={thirdFeatureIcon} />
                    <FeatureSubtitle>Connect</FeatureSubtitle>
                    <FeatureBody>
                        meaningfully through virtual communities of friends, colleagues and mentors
                    </FeatureBody>
                </FeatureCard>
            </FeaturesWrapper>
        </FeaturesContainer>
    );
};

const FeaturesContainer = styled.div`
    height: 800px;
    display: flex;
    border-radius: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #010606;

    @media screen and (max-width: 768px) {
        height: 1100px;
    }

    @media screen and (max-width: 480px) {
        height: 1300px;
    }
`;

const FeaturesWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`;

const FeatureCard = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    max-height: 340px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    //   transition: all 0.2s ease-in-out;

    //   &:hover {
    //     transform: scale(1.02);
    //     transition: all 0.2s ease-in-out;
    //     cursor: pointer;
    //   }
`;

const FeatureIcon = styled.img`
    height: 160px;
    width: 160px;
    margin-bottom: 10px;
`;

const FeaturesHeading = styled.h1`
    font-size: 2.1rem;
    font-weight: bold;
    color: #fff;
    margin-bottom: 64px;

    @media screen and (max-width: 480px) {
        font-size: 2rem;
    }
`;

const FeatureSubtitle = styled.h2`
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 10px;
`;

const FeatureBody = styled.p`
    font-size: 1rem;
    text-align: center;
`;

export default LandingFeatures;
