import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";

export const LandingScrollButton = styled(ScrollLink)`
    border-radius: 50px;
    background: ${({ primary }) => (primary? "#fbaf03": "#010606")};
    white-space: nowrap;
    padding: ${({ large }) => (large? "14px 48px": "12px 30px")};
    color: ${({ dark }) => (dark? "#010606": "#fff")};
    font-size:: ${({ largeFont }) => (largeFont? "20px": "16px")};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({ primary }) => (primary? "#fff": "#fbaf03")};
        color: #000;
    }
`;

export const LandingRouterButton = styled(RouterLink)`
    border-radius: 50px;
    background: ${({ primary }) => (primary? "#fbaf03": "#010606")};
    white-space: nowrap;
    padding: ${({ large }) => (large? "14px 48px": "12px 30px")};
    color: ${({ dark }) => (dark? "#010606": "#fff")};
    font-size:: ${({ largeFont }) => (largeFont? "20px": "16px")};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({ primary }) => (primary? "#fff": "#fbaf03")};
        color: #000;
    }
`;

export const LandingLinkButton = styled.a`
    border-radius: 50px;
    background: ${({ primary }) => (primary? "#fbaf03": "#010606")};
    white-space: nowrap;
    padding: ${({ large }) => (large? "14px 48px": "12px 30px")};
    color: ${({ dark }) => (dark? "#010606": "#fff")};
    font-size:: ${({ largeFont }) => (largeFont? "20px": "16px")};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({ primary }) => (primary? "#fff": "#fbaf03")};
        color: #000;
    }
`;