// import { useEffect } from "react";
// import mixpanel from "../util/mixpanel";

const AppDownload = () => {
    // useEffect(() => {
    //     mixpanel.track("App Download");
    // }, []);
    // const userAgentString = window.navigator.userAgent.toLowerCase();
    // const appStoreUrl =
    //     "https://apps.apple.com/vn/app/blynk-professional-network/id1671068128";
    // const googlePlayUrl =
    //     "https://play.google.com/store/apps/details?id=com.joinblynk.palomino_mobile&hl=en_US&gl=US";
    // if (
    //     userAgentString.includes("(macintosh") ||
    //     userAgentString.includes("(ip")
    // ) {
    //     window.location.replace(appStoreUrl);
    // } else {
    //     window.location.replace(googlePlayUrl);
    // }
    window.location.replace("https://app.joinblynk.com/registration/job-seeker");
    return null;
};

export default AppDownload;
