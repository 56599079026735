import React from "react";
import styled from "styled-components";

// UI Components
import JobBoardNavbar from "../components/layout/JobBoardNavbar";

const Privacy = () => {
    const documentMarkup = (
        <>
            <h1>Privacy Policy</h1>
            <p className="text-dark">Updated 5 January, 2022</p>
            <h5>
                <b>Introduction</b>
            </h5>
            <p className="text-dark">
                Welcome and thank you for choosing to be part of our community
                at BLYNK ("<b>Company</b>", "<b>we</b>", "<b>us</b>", or "
                <b>our</b>"). We are committed to protecting your personal
                information and your right to privacy. If you have any questions
                or concerns about our policy, or our practices with regards to
                your personal information, please{" "}
                <a href="mailto:support@joinblynk.com">contact us</a>.
            </p>
            <p className="text-dark">
                When you visit our mobile application, and use our services, you
                trust us with your personal information. We take your privacy
                very seriously. In this privacy policy, we describe the ways in
                which your information is connected to the services that we
                offer. We seek to explain to you in the clearest way possible
                what information we collect, how we use it and what rights you
                have in relation to it. The details of this policy are important
                to governing the acceptable use of our service ,and we hope that
                you take some time to read through it carefully. If there are
                any terms in this privacy policy with which you do not agree,
                please discontinue use of our App and our services.
            </p>
            <p className="text-dark">
                This privacy policy applies to all information collected through
                our application, ("<b>App</b>"), website, and/or any related
                services, sales, marketing or events (we refer to them
                collectively in this privacy policy as the "<b>Services</b>").
            </p>
            <p className="text-dark">
                Please read this privacy policy carefully as it will help you
                make informed decisions about sharing your personal information
                with us.
            </p>
            <h6> </h6>
            <h5>
                <b>1. WHAT INFORMATION DO WE COLLECT?</b>
            </h5>
            <h6>
                <b>Personal information you disclose to us</b>
            </h6>
            <p className="text-dark">
                <b>
                    <i>In Short:</i>
                </b>
                <i>
                    {" "}
                    We collect personal information that you provide to us such
                    as name, address, contact information, passwords and
                    security data, payment information, and social media login
                    data.
                </i>
            </p>
            <p className="text-dark">
                We collect personal information that you voluntarily provide to
                us when registering for the App, expressing an interest in
                obtaining information about us or our products and services,
                when participating in activities on the App or otherwise
                contacting us.
            </p>
            <p className="text-dark">
                The personal information that we collect depends on the context
                of your interactions with us and the App, the choices you make
                and the products and features you use. The personal information
                we collect can include the following:
            </p>
            <p className="text-dark">
                <b>Name and Contact Data.</b> We collect your first and last
                name, email address, postal address, phone number, and other
                similar contact data.
            </p>
            <p className="text-dark">
                <b>Credentials.</b> We may collect passwords, password hints,
                and similar security information used for authentication and
                account access.
            </p>
            <p className="text-dark">
                <b>Social Media Login Data</b>. We provide you with the option
                to register using social media account details, like your
                Facebook. If you choose to register in this way, we will collect
                the Information described in the section called "HOW DO WE
                HANDLE YOUR SOCIAL LOGINS " below.
            </p>
            <p className="text-dark">
                All personal information that you provide to us must be true,
                complete and accurate, and you must notify us of any changes to
                such personal information.
            </p>
            <p className="text-dark"> </p>
            <h5>
                <b>Information automatically collected</b>
            </h5>
            <p className="text-dark">
                <b>
                    <i>In Short:</i>
                </b>
                <i>
                    {" "}
                    Some information — such as IP address and/or browser and
                    device characteristics — is collected automatically when you
                    visit our App.
                </i>
            </p>
            <p className="text-dark">
                We automatically collect certain information when you visit, use
                or navigate the App. This information does not reveal your
                specific identity (like your name or contact information) but
                may include device and usage information, such as your IP
                address, browser and device characteristics, operating system,
                language preferences, referring URLs, device name, country,
                location, information about how and when you use our App and
                other technical information. This information is primarily
                needed to maintain the security and operation of our App and
                Services, and for our internal analytics and reporting purposes.
            </p>
            <p className="text-dark">
                Like many businesses, we may also collect information through
                cookies and similar technologies.
            </p>
            <h6> </h6>
            <p className="text-dark">
                <b>Information collected through our Application</b>
            </p>
            <p className="text-dark">
                <b>
                    <i>In Short:</i>
                </b>
                <i>
                    {" "}
                    We may collect information regarding your geo-location,
                    mobile device, push notifications, when you use our app.
                </i>
            </p>
            <p className="text-dark">
                If you use our App, we may also collect the following
                information:
            </p>
            <ul>
                <li>
                    <p className="text-dark">
                        <b>Geo-Location Information.</b> We may request access
                        or permission to track location-based information from
                        your mobile device, either continuously or while you are
                        using our mobile application, to provide location-based
                        services. If you wish to change our access or
                        permissions, you may do so in your device's settings.
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        <b>Mobile Device Access.</b> We may request access or
                        permission to certain features from your mobile device,
                        including your mobile device's camera, contacts,
                        reminders, SMS messages, social media accounts, storage,
                        calendar, and other features. If you wish to change our
                        access or permissions, you may do so in your device's
                        settings.
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        <b>Mobile Device Data.</b> We may automatically collect
                        device information (such as your mobile device ID, model
                        and manufacturer), operating system, version information
                        and IP address.
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        <b>Push Notifications.</b> We may request to send you
                        push notifications regarding your account or the mobile
                        application. If you wish to opt-out from receiving these
                        types of communications, you may turn them off in your
                        device's settings.
                    </p>
                </li>
            </ul>
            <p className="text-dark"> </p>
            <h5>
                <b>2. HOW DO WE USE YOUR INFORMATION?</b>
            </h5>
            <p className="text-dark">
                <b>
                    <i>In Short:</i>
                </b>
                <i>
                    {" "}
                    We process your information for purposes based on legitimate
                    business interests, the fulfillment of our contract with
                    you, compliance with our legal obligations, and/or your
                    consent.
                </i>
            </p>
            <p className="text-dark">
                We use personal information collected via our App for a variety
                of business purposes described below. We process your personal
                information for these purposes in reliance on our legitimate
                business interests, in order to enter into or perform a contract
                with you, with your consent, and/or for compliance with our
                legal obligations. We indicate the specific processing grounds
                on which we rely next to each purpose listed below.
            </p>
            <p className="text-dark">
                We use the information we collect or receive:
            </p>
            <ul>
                <li>
                    <p className="text-dark">
                        To facilitate account creation and logon process. If you
                        choose to link your account with us to a third party
                        account * (such as your Google or Facebook account), we
                        use the information you allowed us to collect from those
                        third parties to facilitate account creation and logon
                        process for the performance of the contract. See the
                        section below headed " HOW DO WE HANDLE YOUR SOCIAL
                        LOGINS " for further information.
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        To send administrative information to you. We may use
                        your personal information to send you product, service
                        and new feature information and/or information about
                        changes to our terms, conditions, and policies.
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        Fulfill and manage your orders. We may use your
                        information to fulfill and manage your orders, payments,
                        returns, and exchanges made through the App.
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        To protect our Services. We may use your information as
                        part of our efforts to keep our App safe and secure (for
                        example, for fraud monitoring and prevention).
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        To enable user-to-user communications. We may use your
                        information in order to enable user-to-user
                        communications with each user's consent.
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        To enforce our terms, conditions and policies for
                        Business Purposes, Reasons Legal and Contractual.
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        To respond to legal requests and prevent harm. If we
                        receive a subpoena or other legal request, we may need
                        to inspect the data we hold to determine how to respond.
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        To manage user accounts. We may use your information for
                        the purposes of managing our account and keeping it in
                        working order.
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        To deliver services to the user. We may use your
                        information to provide you with the requested service.
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        To respond to user inquiries/offer support to users. We
                        may use your information to respond to your inquiries
                        and solve any potential issues you might have with the
                        use of our Services.
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        For other Business Purposes. We may use your information
                        for other Business Purposes, such as data analysis,
                        identifying usage trends, determining the effectiveness
                        of our promotional campaigns and to evaluate and improve
                        our App, products, marketing and your experience. We may
                        use and store this information in aggregated and
                        anonymized form so that it is not associated with
                        individual end users and does not include personal
                        information. We will not use identifiable personal
                        information without your consent.
                    </p>
                </li>
            </ul>
            <p className="text-dark"> </p>
            <h5>
                <b>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</b>
            </h5>
            <p className="text-dark">
                <b>
                    <i>In Short:</i>
                </b>
                <i>
                    {" "}
                    We only share information with your consent, to comply with
                    laws, to provide you with services, to protect your rights,
                    or to fulfill business obligations.
                </i>
            </p>
            <p className="text-dark">
                We may process or share data based on the following legal basis:
            </p>
            <ul>
                <li>
                    <p className="text-dark">
                        <b>Consent:</b> We may process your data if you have
                        given us specific consent to use your personal
                        information in a specific purpose.
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        <b>Legitimate Interests:</b> We may process your data
                        when it is reasonably necessary to achieve our
                        legitimate business interests.
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        <b>Performance of a Contract:</b> Where we have entered
                        into a contract with you, we may process your personal
                        information to fulfill the terms of our contract.
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        <b>Legal Obligations:</b> We may disclose your
                        information where we are legally required to do so in
                        order to comply with applicable law, governmental
                        requests, a judicial proceeding, court order, or legal
                        process, such as in response to a court order or a
                        subpoena (including in response to public authorities to
                        meet national security or law enforcement requirements).
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        <b>Vital Interests:</b> We may disclose your information
                        where we believe it is necessary to investigate,
                        prevent, or take action regarding potential violations
                        of our policies, suspected fraud, situations involving
                        potential threats to the safety of any person and
                        illegal activities, or as evidence in litigation in
                        which we are involved.
                    </p>
                </li>
            </ul>
            <p className="text-dark">
                More specifically, we may need to process your data or share
                your personal information in the following situations:
            </p>
            <ul>
                <li>
                    <p className="text-dark">
                        <b>
                            Vendors, Consultants and Other Third-Party Service
                            Providers.
                        </b>{" "}
                        We may share your data with third party vendors, service
                        providers, contractors or agents who perform services
                        for us or on our behalf and require access to such
                        information to do that work. Examples include: payment
                        processing, data analysis, email delivery, hosting
                        services, customer service and marketing efforts. We may
                        allow selected third parties to use tracking technology
                        on the App, which will enable them to collect data about
                        how you interact with the App over time. This
                        information may be used to, among other things, analyze
                        and track data, determine the popularity of certain
                        content and better understand online activity. Unless
                        described in this Policy, we do not share, sell, rent or
                        trade any of your information with third parties for
                        their promotional purposes.
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        <b>Business Transfers.</b> We may share or transfer your
                        information in connection with, or during negotiations
                        of, any merger, sale of company assets, financing, or
                        acquisition of all or a portion of our business to
                        another company.
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        <b>Third-Party Advertisers.</b> We may use third-party
                        advertising companies to serve ads when you visit the
                        App. These companies may use information about your
                        visits to our Website(s) and other websites that are
                        contained in web cookies and other tracking technologies
                        in order to provide advertisements about goods and
                        services of interest to you.
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        We may share your information with our affiliates, in
                        which case we will require those affiliates to honor
                        this privacy policy. Affiliates include our parent
                        company and any subsidiaries, joint venture partners or
                        other companies that we control or that are under common
                        control with us.
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        <b>Business Partners.</b> We may share your information
                        with our business partners to offer you certain
                        products, services or promotions.
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        <b>Other Users.</b> When you share personal information
                        (for example, by posting messages, organization
                        descriptions, contributions or other content to the App)
                        or otherwise interact with public areas of the App, such
                        personal information may be viewed by all users and may
                        be publicly distributed outside the App in perpetuity.
                        If you interact with other users of our App and register
                        through a social network (such as Facebook), your
                        contacts on the social network will see your name,
                        profile photo, and descriptions of your activity.
                        Similarly, other users will be able to view descriptions
                        of your activity, communicate with you within our App,
                        and view your profile.
                    </p>
                </li>
            </ul>
            <p className="text-dark"> </p>
            <h5>
                <b>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</b>
            </h5>
            <p className="text-dark">
                <b>
                    <i>In Short:</i>
                </b>
                <i>
                    {" "}
                    We may indirectly use cookies and other tracking
                    technologies to collect and store your information.
                </i>
            </p>
            <p className="text-dark">
                Cookies are files with a small amount of data that are commonly
                used as anonymous unique identifiers. These are sent to your
                browser from the websites that you visit and are stored on your
                device's internal memory.
            </p>
            <p className="text-dark">
                This Service does not use these “cookies” explicitly. However,
                the App may use third party code and libraries that use
                “cookies” to collect information and improve their services. You
                have the option to either accept or refuse these cookies and
                know when a cookie is being sent to your device. If you choose
                to refuse our cookies, you may not be able to use some portions
                of this Service.
            </p>
            <p className="text-dark">
                We may, therefore, indirectly use cookies and similar tracking
                technologies (like web beacons and pixels) to access or store
                information. Your refusal of cookies from our Site can be
                enforced via modification to your browser settings.
            </p>
            <h5>
                <b>6. HOW DO WE HANDLE YOUR SOCIAL MEDIA LOGINS?</b>
            </h5>
            <p className="text-dark">
                <b>
                    <i>In Short:</i>
                </b>
                <i>
                    {" "}
                    If you choose to register or log in to our services using a
                    social media account, we may have access to certain
                    information about you.
                </i>
            </p>
            <p className="text-dark">
                Our App offers you the ability to register and login using your
                third party social media account details (like your Facebook
                logins). If you choose to do this, we will receive certain
                profile information about you from your social media provider.
                The profile Information we receive may vary depending on the
                social media provider concerned, but will often include your
                name, e-mail address, friends list, profile picture as well as
                other information you choose to make public.
            </p>
            <p className="text-dark">
                We will use the information we receive only for the purposes
                that are described in this privacy policy or that are otherwise
                made clear to you on the App . Please note that we do not
                control, and are not responsible for, other uses of your
                personal information by your third party social media provider.
                We recommend that you review their privacy policy to understand
                how they collect, use and share your personal information, and
                how you can set your privacy preferences on their sites and
                apps.
            </p>
            <p className="text-dark"> </p>
            <h5>
                <b>7. HOW LONG DO WE KEEP YOUR INFORMATION?</b>
            </h5>
            <p className="text-dark">
                <b>
                    <i>In Short:</i>
                </b>
                <i>
                    {" "}
                    We keep your information for as long as necessary to fulfill
                    the purposes outlined in this privacy policy unless
                    otherwise required by law.
                </i>
            </p>
            <p className="text-dark">
                We will only keep your personal information for as long as it is
                necessary for the purposes set out in this privacy policy,
                unless a longer retention period is required or permitted by law
                (such as tax, accounting or other legal requirements).
            </p>
            <p className="text-dark">
                When we have no ongoing legitimate business need to process your
                personal information, we will either delete or anonymize it, or,
                if this is not possible (for example, because your personal
                information has been stored in backup archives), then we will
                securely store your personal information and isolate it from any
                further processing until deletion is possible.
            </p>
            <p className="text-dark"> </p>
            <h6> </h6>
            <h5>
                <b>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</b>
            </h5>
            <p className="text-dark">
                <b>
                    <i>In Short:</i>
                </b>
                <i>
                    {" "}
                    We aim to protect your personal information through a system
                    of organizational and technical security measures.
                </i>
            </p>
            <p className="text-dark">
                We have implemented appropriate technical and organizational
                security measures designed to protect the security of any
                personal information we process. However, please also remember
                that we cannot guarantee that the internet itself is 100%
                secure. Although we will do our best to protect your personal
                information, transmission of personal information to and from
                our App is at your own risk. You should only access the services
                within a secure environment.
            </p>
            <h6> </h6>
            <h6> </h6>
            <h5>
                <b>9. DO WE COLLECT INFORMATION FROM MINORS?</b>
            </h5>
            <p className="text-dark">
                <b>
                    <i>In Short:</i>
                </b>
                <i>
                    {" "}
                    We do not knowingly collect data from or market to children
                    under 13 years of age.
                </i>
            </p>
            <p className="text-dark">
                We do not knowingly solicit data from or market to children
                under 13 years of age. By using the App, you represent that you
                are at least 13 or that you are the parent or guardian of such a
                minor and consent to such minor dependent's use of the App. If
                we learn that personal information from users less than 13 years
                of age has been collected, we may deactivate the account and
                take reasonable measures to promptly delete such data from our
                records. If you become aware of any data we have collected from
                children under age 13, please{" "}
                <a href="mailto:support@joinblynk.com">contact us</a>.
            </p>
            <h6> </h6>
            <h5>
                <b>10. WHAT ARE YOUR PRIVACY RIGHTS?</b>
            </h5>
            <p className="text-dark">
                <b>
                    <i>In Short:</i>
                </b>
                <i>
                    {" "}
                    You may review, change, or terminate your account at any
                    time.
                </i>
            </p>
            <h6>
                <b>Account Information</b>
            </h6>
            <p className="text-dark">
                If you would at any time like to review or change the
                information in your account or terminate your account, you can:
            </p>
            <ul>
                <li>
                    <p className="text-dark">
                        Log into your account settings and update your user
                        account.
                    </p>
                </li>
                <li>
                    <p className="text-dark">
                        <a href="mailto:support@joinblynk.com">Contact us</a>{" "}
                        using the contact information provided.
                    </p>
                </li>
            </ul>
            <p className="text-dark">
                Upon your request to terminate your account, we will deactivate
                or delete your account and information from our active
                databases. However, some information may be retained in our
                files to prevent fraud, troubleshoot problems, assist with any
                investigations, enforce our Terms of Use and/or comply with
                legal requirements.
            </p>
            <p className="text-dark">
                <b>Opting out of email marketing:</b> You can unsubscribe from
                our marketing email list at any time by clicking on the
                unsubscribe link in the emails that we send or by contacting us
                using the details provided on the site. You will then be removed
                from the marketing email list. However, we may still need to
                send you service-related emails that are necessary for the
                administration and use of your account. To otherwise opt-out,
                you may:
            </p>
            <ul>
                <li>
                    <p className="text-dark">
                        <a href="mailto:support@joinblynk.com">Contact us</a>{" "}
                        using the contact information provided.
                    </p>
                </li>
            </ul>
            <h6> </h6>
            <h5>
                <b>11. CONTROLS FOR DO-NOT-TRACK FEATURES</b>
            </h5>
            <p className="text-dark">
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track ("DNT") feature or setting
                you can activate to signal your privacy preference not to have
                data about your online browsing activities monitored and
                collected. No uniform technology standard for recognizing and
                implementing DNT signals has been finalized. As such, we do not
                currently respond to DNT browser signals or any other mechanism
                that automatically communicates your choice not to be tracked
                online. If a standard for online tracking is adopted that we
                must follow in the future, we will inform you about that
                practice in a revised version of this privacy policy.
            </p>
            <p className="text-dark"> </p>
            <h5>
                <b>12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</b>
            </h5>
            <p className="text-dark">
                <b>
                    <i>In Short:</i>
                </b>
                <i>
                    {" "}
                    Yes, if you are a resident of California, you are granted
                    specific rights regarding access to your personal
                    information.
                </i>
            </p>
            <p className="text-dark">
                California Civil Code Section 1798.83, also known as the "Shine
                The Light" law, permits our users who are California residents
                to request and obtain from us, once a year and free of charge,
                information about categories of personal information (if any) we
                disclosed to third parties for direct marketing purposes and the
                names and addresses of all third parties with which we shared
                personal information in the immediately preceding calendar year.
                If you are a California resident and would like to make such a
                request, please submit your request in writing to us using the
                contact information provided on the site.
            </p>
            <p className="text-dark">
                If you are under 18 years of age, reside in California, and have
                a registered account with the App, you may request removal of
                unwanted data that you publicly post on the App. To request
                removal of such data, please{" "}
                <a href="mailto:support@joinblynk.com">contact us</a> using the
                contact information provided on the site, and include the email
                address associated with your account and a statement that you
                reside in California. This statement must be supplemented by
                documentation of your residence in the state of California, and
                if you are a minor, provide documentation as a dependent of a
                California resident. We will make sure the data is not publicly
                displayed on the App, but please be aware that the data may not
                be completely or comprehensively removed from our systems.
            </p>
            <p className="text-dark"> </p>
            <h6> </h6>
            <h5>
                <b>13. DO WE MAKE UPDATES TO THIS POLICY?</b>
            </h5>
            <p className="text-dark">
                <b>
                    <i>In Short: </i>
                </b>
                <i>
                    Yes, we will update this policy as necessary to stay
                    compliant with relevant laws.
                </i>
            </p>
            <p className="text-dark">
                We may update this privacy policy from time to time. The updated
                version will be indicated by an updated "Revised" date and the
                updated version will be effective as soon as it is accessible.
                If we make material changes to this privacy policy, we may
                notify you either by prominently posting a notice of such
                changes or by directly sending you a notification. We encourage
                you to review this privacy policy frequently to be informed of
                how we are protecting your information.
            </p>
            <h5>
                <b>Contact Us</b>
            </h5>
            <p className="text-dark">
                If you have any questions, concerns or suggestions about our
                Privacy Policy, please do not hesitate to contact us at{" "}
                <a href="mailto:support@joinblynk.com">
                    {"support@joinblynk.com"}
                </a>
                .
            </p>
            <p className="text-dark">
                Thank you once again for choosing BLYNK.
            </p>
        </>
    );

    return (
        <Container className="main-content right-chat-active">
            <JobBoardNavbar themeButton={true} />
            <div className="w-350">{documentMarkup}</div>
        </Container>
    );
};

const Container = styled.div`
    padding-top: 110px;
    padding-left: 18%;
    padding-right: 18%;
    max-width: 100%;
    padding-bottom: 110px;
`;

export default Privacy;
