import { useEffect } from "react";
import mixpanel from "../util/mixpanel";

const ResumeForm = () => {
    useEffect(() => {
        mixpanel.track("Resume Click");
        // window.location.replace("https://forms.gle/LvvL35wyozPcpecEA");
        // window.location.replace("https://airtable.com/shrBIMMUnfHb8npYx");
        window.location.replace("https://app.joinblynk.com/registration/job-seeker");
    }, []);
    return null;
};

export default ResumeForm;
