import React from "react";
import styled from "styled-components";

// UI Components
import JobBoardNavbar from "../components/layout/JobBoardNavbar";
import { LandingLinkButton } from "../landing/LandingButton";

const JobseekerAbout = () => {
    const documentMarkup = (
        <>
            <h1 className="fw-bold pb-4">About Us</h1>
            <h2 className="fw-600 pb-2">
                Welcome to BLYNK – Your Inside Connection to Your Dream Career
            </h2>
            <p className="text-dark">
                At BLYNK, our mission is clear – to be your inside connection to
                the career you've always dreamed of. We're not just a job
                platform; we're your dedicated partner in navigating the
                intricacies of the job market and connecting you directly with
                the companies you aspire to work for.
            </p>
            <h2 className="fw-600">Unleashing Your Potential</h2>
            <p className="text-dark">
                In today's competitive job market, finding the right career path
                can be challenging. BLYNK is here to simplify that journey for
                you. We work directly with the companies that align with your
                dreams, making your aspirations a reality.
            </p>

            <h1 className="fw-bold pb-4">How BLYNK Works for You</h1>
            <h2 className="fw-600">1. Personalized Assessment</h2>
            <p className="text-dark">
                When you join BLYNK, we go beyond the traditional job search.
                Our team reviews your resume, delving into your talents, skills,
                and experiences. This personalized approach ensures that we
                truly understand what makes you exceptional.
            </p>
            <h2 className="fw-600">2. Connecting You with Dream Companies</h2>
            <p className="text-dark">
                Armed with insights from your resume and our deep understanding
                of your strengths, we embark on a mission to find the exact
                company and career that is tailor-made for you. BLYNK is your
                inside connect to the companies you've always dreamed of being a
                part of.
            </p>
            <h2 className="fw-600">3. Your Dream Career Unveiled</h2>
            <p className="text-dark">
                Say goodbye to generic job searches. BLYNK reveals opportunities
                uniquely suited to your profile, putting you in the driver's
                seat of your career journey. We're not just finding you a job;
                we're uncovering your dream career.
            </p>
            <h2 className="fw-600">Join BLYNK Now</h2>
            <p className="text-dark">
                Ready to turn your career dreams into reality? Join BLYNK today
                and let us be your inside connection to the opportunities that
                match your talents, skills, and aspirations.
            </p>
            <div>
                <LandingLinkButton
                    href="/registration/job-seeker"
                    className="float-right"
                >
                    Join Now
                </LandingLinkButton>
            </div>
        </>
    );

    return (
        <Container className="main-content right-chat-active">
            <JobBoardNavbar themeButton={false} />
            <div className="w-350">{documentMarkup}</div>
        </Container>
    );
};

const Container = styled.div`
    padding-top: 110px;
    padding-left: 18%;
    padding-right: 18%;
    max-width: 100%;
    padding-bottom: 110px;
`;

export default JobseekerAbout;
