import { React, useState } from "react";

const ThemeToggle = () => {
    let clickedClass = "clicked";
    const body = document.body;
    const lightTheme = "theme-light";
    const darkTheme = "theme-dark";
    const [icon, setIcon] = useState("feather-moon");
    let theme;
  
    if (localStorage) {
      theme = localStorage.getItem("theme");
    }
  
    if (theme === lightTheme || theme === darkTheme) {
      body.classList.add(theme);
    } else {
      body.classList.add(lightTheme);
    }
  
    const switchTheme = e => {
      if (theme === darkTheme) {
        body.classList.replace(darkTheme, lightTheme);
        e.target.classList.remove(clickedClass);
        localStorage.setItem("theme", "theme-light");
        setIcon("feather-moon");
        theme = lightTheme;
      } else {
        body.classList.replace(lightTheme, darkTheme);
        e.target.classList.add(clickedClass);
        localStorage.setItem("theme", "theme-dark");
        setIcon("feather-sun");
        theme = darkTheme;
      }
    }
  
    return (
      <span
          className={`pointer p-2 text-center ms-3 menu-icon chat-active-btn ${theme === "dark" ? clickedClass : ""}`}
          onClick={e => switchTheme(e)}>
            <i className= {`${icon} font-xl text-current`}/>
      </span>
    )
  }
  
  export default ThemeToggle;