import { useEffect } from "react";
import PropTypes from "prop-types";

const Wildcard = (props) => {
    useEffect(() => {
        const pathName = window.location.pathname;
        window.location.replace(`${props.url}${pathName}`);
    }, []);
    return null;
};

Wildcard.propTypes = {
    url: PropTypes.string.isRequired
};

export default Wildcard;
