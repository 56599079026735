import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// UI Components
import {
    LandingRouterButton,
    LandingLinkButton,
    LandingScrollButton,
} from "./LandingButton";

const LandingInfo = (props) => {
    return (
        <>
            <InfoContainer
                lightBackground={props.lightBackground}
                id={props.id}
            >
                <InfoWrapper>
                    <InfoRow imageStart={props.imageStart} className="pt-4">
                        <FirstColumn>
                            <TextWrapper>
                                <Title>{props.title}</Title>
                                <Heading lightText={props.lightText}>
                                    {props.headline}
                                </Heading>
                                <Subtitle darkText={props.darkText}>
                                    {props.markup
                                        ? props.markup
                                        : props.description}
                                </Subtitle>
                                <ButtonWrapper>
                                    {props.scroll ? (
                                        <LandingScrollButton
                                            to={props.target}
                                            smooth={true}
                                            duration={500}
                                            spy={true}
                                            exact="true"
                                            offset={-80}
                                            primary={props.primary ? 1 : 0}
                                            dark={props.dark ? 1 : 0}
                                        >
                                            {props.buttonLabel}
                                        </LandingScrollButton>
                                    ) : props.external ? (
                                        <LandingLinkButton
                                            href={props.target}
                                            smooth={true}
                                            duration={500}
                                            spy={true}
                                            exact="true"
                                            offset={-80}
                                            primary={props.primary ? 1 : 0}
                                            dark={props.dark ? 1 : 0}
                                        >
                                            {props.buttonLabel}
                                        </LandingLinkButton>
                                    ) : (
                                        <LandingRouterButton
                                            to={props.target}
                                            smooth={true}
                                            duration={500}
                                            spy={true}
                                            exact="true"
                                            offset={-80}
                                            primary={props.primary ? 1 : 0}
                                            dark={props.dark ? 1 : 0}
                                        >
                                            {props.buttonLabel}
                                        </LandingRouterButton>
                                    )}
                                </ButtonWrapper>
                            </TextWrapper>
                        </FirstColumn>
                        <SecondColumn>
                            <ImageWrapper>
                                <Image src={props.image} alt={props.alt} />
                            </ImageWrapper>
                        </SecondColumn>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    );
};

const InfoContainer = styled.div`
    color: #fff;
    border-radius: 12px;
    height: auto;
    background: ${({ lightBackground }) =>
        lightBackground ? "#f9f9f9" : "#010606"};

    @media and screen (max-width: 768px) {
        padding: 100px 0;
    }
`;

const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: auto;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`;

const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({ imageStart }) =>
        imageStart ? `"col2 col1"` : `"col1 col2"`};

    @media screen and (max-width: 768px) {
        grid-template-areas: ${({ imageStart }) =>
            imageStart ? `"col1" "col2"` : `"col1 col1" "col2 col2"`};
    }
`;

const FirstColumn = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
`;

const SecondColumn = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
`;

const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
`;

const Title = styled.p`
    color: #fbaf03;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`;

const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
`;

const ImageWrapper = styled.div`
    max-width: 555px;
    height: 100%;
`;

const Image = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
`;

LandingInfo.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    lightBackground: PropTypes.bool.isRequired,
    lightText: PropTypes.bool.isRequired,
    darkText: PropTypes.bool.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    dark: PropTypes.bool.isRequired,
    primary: PropTypes.bool.isRequired,
};

export default LandingInfo;
