import React, { Fragment, useRef, useState } from "react";
import styled from "styled-components";
import { conceal, reveal } from "../util/cryptography";

// UI Components
import JobBoardNavbar from "../components/layout/JobBoardNavbar";

const CareerConnections = () => {
    const params = new URLSearchParams(window.location.search);
    const employer = params.get("e");
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const majorRef = useRef(null);
    const phoneRef = useRef(null);
    const linkedinRef = useRef(null);
    const [company, setCompany] = useState();
    const [success, setSuccess] = useState();
    const employers = [
        "Baltimore Corps",
        "BGE",
        "Federal Housing Finance Agency",
        "FemEquity",
        "Freshmeet U",
        "Gordon Feinblatt",
        // "Harkins Builders",
        // "LifeBridge Health",
        // "McCormick",
        "New York Life",
        "Red Vector"
    ];
    const contactInfo = localStorage.getItem("m")
        ? JSON.parse(reveal(localStorage.getItem("m")))
        : null;

    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            name: nameRef.current.value,
            email: emailRef.current.value,
            major: majorRef.current.value,
            phone: phoneRef.current.value,
            linkedin: linkedinRef.current.value,
        };
        const message = conceal(JSON.stringify(payload));
        localStorage.setItem("m", message);
        window.location.reload();
    };

    const handleEdit = (e) => {
        e.preventDefault();
        localStorage.removeItem("m");
        window.location.reload();
    };

    const handleShare = (e) => {
        e.preventDefault();
        const link =
            "https://script.google.com/macros/s/AKfycbxMz4KQFaa5sxTqaUCPtbd85u6KFVNooufhKsfoq39s6b42izj6zfW2X7GT9hglXgLsCQ/exec";
        fetch(link, {
            method: "POST",
            redirect: "follow",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "text/plain;charset=utf-8",
            },
            body: JSON.stringify({
                ...contactInfo,
                sheetName: employer !== null ? employer : company,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                setSuccess(true);
                console.log(res);
            });
        setSuccess(true);
    };

    const handleRadioChange = (e) => {
        e.preventDefault();
        setCompany(e.target.value);
    };

    const handleDoneSharing = (e) => {
        e.preventDefault();
        setSuccess(false);
        window.location.replace("/career-connections");
    };

    const contactInfoMarkup = contactInfo !== null &&
        contactInfo !== undefined && (
            <>
                <div style={{ minHeight: "900px", maxWidth: "600px" }}>
                    <div
                        className="mb-2"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "40%",
                            }}
                        >
                            <Logo
                                className="me-2"
                                src="https://pbs.twimg.com/profile_images/895299830188265472/fiH4GxKM_400x400.jpg"
                            />
                            <Logo src="https://pbs.twimg.com/profile_images/783316417361870848/2ei-n7YJ_400x400.jpg" />
                        </div>
                    </div>
                    <a
                        onClick={handleEdit}
                        style={{ float: "right", cursor: "pointer" }}
                    >
                        <i className="btn-round-sm font-xs text-grey-900 feather-edit-2 me-2 bg-greylight" />
                    </a>
                    <span>
                        <b>Name: </b> {contactInfo.name}
                    </span>
                    <br />
                    <span>
                        <b>Email: </b> {contactInfo.email}
                    </span>
                    <br />
                    <span>
                        <b>Major: </b> {contactInfo.major}
                    </span>
                    <br />
                    <span>
                        <b>Phone: </b> {contactInfo.phone}
                    </span>
                    <br />
                    <span style={{ overflow: "clip" }}>
                        <b>LinkedIn: </b> {contactInfo.linkedin}
                    </span>
                    {
                        <form onSubmit={handleShare}>
                            <br />
                            {employer === null && <b>Choose a Company</b>}
                            <br />
                            {employer === null &&
                                employers.map((org, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <span className="fw-500 text-dark font-xsss">
                                                <input
                                                    className="me-4 pointer"
                                                    type="radio"
                                                    name="company"
                                                    required
                                                    value={org}
                                                    onChange={handleRadioChange}
                                                />
                                                {org}
                                            </span>
                                            <br />
                                        </Fragment>
                                    );
                                })}
                            <SubmitButton
                                type="submit"
                                className="float-right mt-4"
                                value="Confirm"
                            />
                        </form>
                    }
                </div>
            </>
        );

    const formMarkup = (
        <>
            <FormWrapper>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "40%",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                >
                    <Logo
                        className="me-2"
                        src="https://pbs.twimg.com/profile_images/895299830188265472/fiH4GxKM_400x400.jpg"
                    />
                    <Logo src="https://pbs.twimg.com/profile_images/783316417361870848/2ei-n7YJ_400x400.jpg" />
                </div>
                <form onSubmit={handleSubmit} method="post">
                    <FormInput
                        type="text"
                        name="name"
                        id="name"
                        ref={nameRef}
                        placeholder="Full Name"
                        required
                        className="bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
                    />
                    <FormInput
                        type="email"
                        name="email"
                        id="email"
                        ref={emailRef}
                        placeholder="Email"
                        required
                        className="bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
                    />
                    <FormInput
                        type="text"
                        name="major"
                        id="major"
                        ref={majorRef}
                        placeholder="Major"
                        className="bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
                    />
                    <FormInput
                        type="tel"
                        name="phone"
                        id="phone"
                        ref={phoneRef}
                        placeholder="Phone"
                        className="bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
                    />
                    <FormInput
                        type="url"
                        name="linkedin"
                        id="linkedin"
                        ref={linkedinRef}
                        placeholder="LinkedIn Url"
                        className="bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
                    />
                    <SubmitButton
                        type="submit"
                        className="float-right"
                        value="Save"
                    />
                </form>
            </FormWrapper>
        </>
    );

    const renderView = () => {
        if (success) {
            return (
                <Container className="main-content right-chat-active">
                    <JobBoardNavbar themeButton={false} />
                    <div className="w-350" style={{ minHeight: 800 }}>
                        <h1 className="fw-bold text-center">
                            BSA Career Connections Intake
                        </h1>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "40%",
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}
                        >
                            <Logo
                                className="me-2"
                                src="https://pbs.twimg.com/profile_images/895299830188265472/fiH4GxKM_400x400.jpg"
                            />
                            <Logo src="https://pbs.twimg.com/profile_images/783316417361870848/2ei-n7YJ_400x400.jpg" />
                        </div>
                        <p
                            className="text-center mb-0"
                            style={{ fontSize: 56 }}
                        >
                            <i className="feather-check-circle text-success mb-0" />
                        </p>
                        <br />
                        <p className="text-center">
                            <b className="text-success">
                                Your information has been sent to{" "}
                                {employer !== null ? employer : company}.
                            </b>
                        </p>
                        <form onSubmit={handleDoneSharing}>
                            <SubmitButton
                                type="submit"
                                className="float-right mt-4"
                                value="Done"
                            />
                        </form>
                    </div>
                </Container>
            );
        } else {
            return (
                <Container className="main-content right-chat-active">
                    <JobBoardNavbar themeButton={false} />
                    <div className="w-350">
                        <h1 className="fw-bold text-center">
                            BSA Career Connections Intake
                        </h1>
                        {employer !== null && employer !== undefined && (
                            <h2 className="text-center">
                                Share your info with{" "}
                                {decodeURIComponent(employer)}
                            </h2>
                        )}
                        {contactInfo === null ? formMarkup : contactInfoMarkup}
                    </div>
                </Container>
            );
        }
    };

    return renderView();
};

const Container = styled.div`
    padding-top: 110px;
    padding-left: 9%;
    padding-right: 9%;
    max-width: 100%;
    display: flex;
    justify-content: center;
`;

const FormWrapper = styled.div`
    diplay: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    max-width: 600px;
    min-height: 650px;
`;

const FormInput = styled.input`
    width: 100%;
    height: 35px;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.6);
`;

const Logo = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 20px;
    border: 2px solid #d3dfe8;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 36px;
    margin-bottom: 36px;
`;

export const SubmitButton = styled.input`
    border-radius: 50px;
    background: ${({ primary }) => (primary ? "#fbaf03" : "#010606")};
    white-space: nowrap;
    padding: ${({ large }) => (large ? "14px 48px" : "12px 30px")};
    color: ${({ dark }) => (dark ? "#010606" : "#fff")};
    font-size:: ${({ largeFont }) => (largeFont ? "20px" : "16px")};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({ primary }) => (primary ? "#fff" : "#fbaf03")};
        color: #000;
    }
`;

export default CareerConnections;
