import { useEffect } from "react";
import PropTypes from "prop-types";

const Redirect = (props) => {
    useEffect(() => {
        window.location.replace(props.url);
    }, []);
    return null;
};

Redirect.propTypes = {
    url: PropTypes.string.isRequired
};

export default Redirect;
