import {
    SET_USER,
    SET_AUTHENTICATED,
    SET_UNAUTHENTICATED,
    LOADING_USER,
    LIKE_POST,
    DISLIKE_POST,
    UNLIKE_POST,
    UNDISLIKE_POST,
    MARK_NOTIFICATIONS_READ,
} from "../types";
import mixpanel from "../../util/mixpanel";

const initialState = {
    authenticated: false,
    loading: false,
    credentials: {},
    likes: [],
    dislikes: [],
    notifications: [],
    education: []
};

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_AUTHENTICATED:
            return {
                ...state,
                authenticated: true
            };
        case SET_UNAUTHENTICATED:
            return initialState;
        case SET_USER:
            if (action.payload.credentials.userId) {
                mixpanel.identify(action.payload.credentials.userId);
            }
            return {
                authenticated: true,
                loading: false,
                ...action.payload
            };
        case LOADING_USER:
            return {
                ...state,
                loading: true
            };
        case LIKE_POST:
            return {
                ...state,
                likes: [
                    ...state.likes,
                    {
                        userHandle: state.credentials.handle,
                        postId: action.payload.postId
                    }
                ]
            };
        case DISLIKE_POST:
            return {
                ...state,
                dislikes: [
                    ...state.dislikes,
                    {
                        userHandle: state.credentials.handle,
                        postId: action.payload.postId
                    }
                ]
            };
        case UNLIKE_POST:
            return {
                ...state,
                likes: state.likes.filter(like => like.postId !== action.payload.postId)
            };
        case UNDISLIKE_POST:
            return {
                ...state,
                dislikes: state.dislikes.filter(dislike => dislike.postId !== action.payload.postId)
            };            
        case MARK_NOTIFICATIONS_READ:
            state.notifications.forEach(notif => notif.read = true);
            return {
                ...state
            };         
        default:
            return state;
    }
}