import React from "react";
import styled from "styled-components";

// UI Components
import JobBoardNavbar from "../components/layout/JobBoardNavbar";
import { LandingLinkButton } from "../landing/LandingButton";

const EmployerAbout = () => {
    const documentMarkup = (
        <>
            <h1 className="fw-bold pb-4">About Us</h1>
            <h2 className="fw-600 pb-2">
                Welcome to BLYNK – The Gateway to Your Diverse Talent Pipeline
            </h2>
            <p className="text-dark">
                At BLYNK, we are committed to reshaping the future of workforce
                development. Today, employers face the challenge of building
                diverse talent pipelines that go beyond conventional boundaries
                and ensure the success of their organization. That's where BLYNK
                steps in as your one-stop location to curate a pool of diverse
                talent tailored to your needs.
            </p>
            <h2 className="fw-600">The BLYNK Difference</h2>
            <p className="text-dark">
                Diversity is not just a checkbox; it's a strategy for success.
                BLYNK stands out by not just finding you talent but by finding
                you the best talent. We understand that your organization's
                triumph lies in having a team that brings unique perspectives
                and exceptional skills to the table.
            </p>
            <h2 className="fw-600">Building Diversity from All Levels</h2>
            <p className="text-dark">
                We source talent from a wide spectrum, including community
                colleges, Historically Black Colleges and Universities (HBCUs),
                Predominantly White Institutions (PWIs), Minority-Serving
                Institutions (MSIs), and even high schools. Our commitment to
                inclusivity ensures that your organization has access to a rich,
                varied talent pool that brings unique perspectives and skills to
                the table.
            </p>
            <h2 className="fw-600">Simplifying Your Talent Search</h2>
            <p className="text-dark">
                With BLYNK, employers can seamlessly search, contact, and hire
                from our curated pool of diverse talent. Our platform empowers
                you to identify candidates who align with your organization's
                values and needs, streamlining the hiring process for maximum
                efficiency.
            </p>
            <h2 className="fw-600">Join BLYNK Now</h2>
            <p className="text-dark">
                Experience the ease of building a diverse talent pipeline like
                never before. Join BLYNK today and transform the way you
                discover, connect, and hire exceptional talent.
            </p>
            <div>
                <LandingLinkButton href="/registration/employer" className="float-right">
                    Get Started
                </LandingLinkButton>
            </div>
        </>
    );

    return (
        <Container className="main-content right-chat-active">
            <JobBoardNavbar themeButton={false} />
            <div className="w-350">{documentMarkup}</div>
        </Container>
    );
};

const Container = styled.div`
    padding-top: 110px;
    padding-left: 18%;
    padding-right: 18%;
    max-width: 100%;
    padding-bottom: 110px;
`;

export default EmployerAbout;
