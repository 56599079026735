import {
    SET_POSTS,
    LIKE_POST,
    DISLIKE_POST,
    UNLIKE_POST,
    UNDISLIKE_POST,
    LOADING_DATA,
    DELETE_POST,
    CREATE_POST,
    SET_POST,
    SUBMIT_COMMENT,
    SET_JOBS,
    ADD_EDUCATION,
    SET_PROFILE,
    UPDATE_PROFILE,
    REMOVE_EDUCATION,
    SET_EVENTS,
    SET_RESERVATIONS,
    ADD_RESERVATION,
    STOP_LOADING_DATA
} from "../types";

const initialState = {
    posts: [],
    post: {},
    loading: false,
    jobs: [],
    profile: {
        education: []
    },
    events: [],
    reservations: []
};

export default function(state = initialState, action) {
    switch(action.type) {
        case LOADING_DATA:
            return {
                ...state,
                loading: true
            };
        case SET_POSTS:
            return {
                ...state,
                posts: action.payload,
                loading: false
            };
        case SET_POST:
            return {
                ...state,
                post: action.payload
            };
        case LIKE_POST:
        case DISLIKE_POST:
        case UNLIKE_POST:
        case UNDISLIKE_POST:
            let index = state.posts.findIndex(post => post.postId === action.payload.postId);
            state.posts[index] = action.payload;
            if (state.post.postId === action.payload.postId) {
                state.post = action.payload;
            }
            return {
                ...state
            };
        case DELETE_POST:
            return {
                ...state,
                posts: [
                    ...state.posts.filter(post => post.postId !== action.payload)
                ]
            };
        case CREATE_POST:
            return {
                ...state,
                posts: [
                    action.payload,
                    ...state.posts
                ]
            };
        case SUBMIT_COMMENT:
            const commentedPostIndex = state.posts.findIndex(post => post.postId === state.post.postId);
            state.posts[commentedPostIndex].commentCount++;
            return {
                ...state,
                post: {
                    ...state.post,
                    comments: [
                        action.payload,
                        ...state.post.comments
                    ]
                },
            };
        case SET_JOBS:
            return {
                ...state,
                jobs: action.payload.results,
                loading: false
            };
        case ADD_EDUCATION:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    education: [
                        ...state.profile.education,
                        action.payload
                    ].sort((a, b) => {
                        return b.classYear - a.classYear
                    })
                }
            };   
        case REMOVE_EDUCATION:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    education: state.profile.education.filter(entry => entry.id !== action.payload)
                }
            };
        case SET_PROFILE:
            return {
                ...state,
                profile: action.payload
            };
        case UPDATE_PROFILE:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    bio: action.payload.bio,
                    website: action.payload.website,
                    location: action.payload.location
                }
            };
        case SET_EVENTS:
            return {
                ...state,
                events: action.payload,
                loading: false
            };
        case SET_RESERVATIONS:
            return {
                ...state,
                reservations: action.payload,
                loading: false
            };
        case ADD_RESERVATION:
            return {
                ...state,
                reservations: [
                    ...state.reservations,
                    action.payload
                ],
                loading: false
            }
        case STOP_LOADING_DATA:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};