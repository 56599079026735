import React from "react";
import styled from "styled-components";

// UI Components
import JobBoardNavbar from "../components/layout/JobBoardNavbar";
import LandingInfo from "../landing/LandingInfo";
import LandingFeatures from "../landing/LandingFeatures";
import firstSectionImage from "../images/landing/business_deal.svg";
import secondSectionImage from "../images/landing/stand_out.svg";
import thirdSectionImage from "../images/landing/businesswoman.svg";

const overviewSectionData = {
    id: "about",
    lightBackground: true,
    lightText: false,
    lightTextDescription: false,
    title: "Who We Are",
    headline: "About Us",
    description:
        "BLYNK is the modern solution for bringing professionals from all walks of life to the center stage. We do the heavy lifting necessary to connect employers to underestimated job seekers, so that we can create a world where all leaders have the power to realize their vision.",
    buttonLabel: "Learn more",
    imageStart: false,
    image: firstSectionImage,
    alt: "handshake",
    dark: false,
    primary: false,
    darkText: true,
    // target: "/companies/blynk-1153389",
    target: "/about-jobseeker",
    scroll: false,
    external: true
};

const valuesSectionData = {
    id: "vision",
    lightBackground: false,
    lightText: true,
    lightTextDescription: true,
    title: "What We're About",
    headline: "Mission & Vision",
    buttonLabel: "Explore",
    imageStart: true,
    image: secondSectionImage,
    alt: "standing out from the crowd",
    dark: false,
    primary: true,
    darkText: false,
    target: "/jobs",
    scroll: false,
    external: true
};

const productSectionData = {
    lightBackground: true,
    lightText: false,
    lightTextDescription: false,
    title: "What We're Building",
    headline: "Product Roadmap",
    description:
        "The BLYNK is a work in progress, so there is a lot to look forward to. Today our job board offers a way to search for and post job opportunities, and to find talented individuals looking to join your team. The future of BLYNK is an end-to-end workforce development ecosystem that uses data driven insights to guide users on their own tailored career path that acquaints them with all of the skills, knowledge, people and experiences to thrive in their own careers and in their organizations.",
    buttonLabel: "Join now",
    imageStart: false,
    image: thirdSectionImage,
    alt: "businesswoman",
    dark: false,
    primary: false,
    darkText: true,
    target: "/sign-up",
    scroll: false,
};

const About = () => {
    const body = document.body;
    body.classList.replace("theme-dark", "theme-light");

    const descriptionMarkup = (
        <div>
            <Subtitle darkText={true}>
                BLYNK, which stands for “Business Leaders You Need to Know,” is
                a venture-backed software startup aimed at connecting the best
                and brightest professionals of diverse backgrounds to great
                career opportunities.
            </Subtitle>

            <Subtitle darkText={true}>
                We intend to revolutionize the workforce by providing a platform
                for job searching, networking, mentorship, and upskilling, and
                partner with a wide range of stakeholders in academia,
                government, and commerce to accomplish our vision of creating an
                Equal Opportunity Ecosystem.
            </Subtitle>

            <Subtitle darkText={true}>
                We look forward to helping you navigate your career, keeping
                your finger on the pulse of a dynamic labor market, and enabling
                you to adapt to changes “in the BLYNK of an eye.”
            </Subtitle>
        </div>
    );

    const visionMarkUp = (
        <div>
            <Subtitle darkText={false}>
                We envision a world where exceptional people access exceptional
                opportunities so that they may bring their strengths to the
                table and serve in ways that make that world better for all.
            </Subtitle>

            <Subtitle darkText={false}>
                We're convinced that being exceptional doesn't look any one
                particular way, so with all of our differences, we want to help
                those around us identify what's exeptional about them, so that
                they may leverage their potential to take charge of their
                destinies.
            </Subtitle>
        </div>
    );

    return (
        <Container className="main-content right-chat-active">
            <JobBoardNavbar themeButton={false} />
            <Column>
                <LandingInfo
                    {...overviewSectionData}
                    markup={descriptionMarkup}
                />
                <LandingInfo {...valuesSectionData} markup={visionMarkUp} />
                <LandingInfo {...productSectionData} />
                <LandingFeatures />
            </Column>
        </Container>
    );
};

const Container = styled.div`
    padding-top: 110px;
    padding-left: 4%;
    padding-right: 4%;
    max-width: 100%;
    padding-bottom: 110px;
    height: 100%;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 36px;
`;

const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
`;

export default About;
