import CryptoJS from "crypto-js";

export const conceal = (messageText) => {
  const password = "8c436b239a14c1369f0b44f0f6399974";
  const encryptedText = CryptoJS.AES.encrypt(messageText, password).toString();
  return encryptedText;
};

export const reveal = (cipherText) => {
    const password = "8c436b239a14c1369f0b44f0f6399974";
    const bytes = CryptoJS.AES.decrypt(cipherText, password);
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedText;
  };
