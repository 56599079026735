import React from "react";
import treeDiagram from "../images/problem_tree_legend.svg";

const Diagram = () => {
    return (
        <div className="bg-white p-4 position-absolute w-100 h-100" style={{overflowX: "scroll"}}>
            <img src={treeDiagram} alt="Labor Shortage Decision Tree Diagram" />
        </div>
    );
};

export default Diagram;
